import Web3 from 'web3'
import BigNumber from 'bignumber.js'

export { default as formatAddress } from './formatAddress'

export const bnToDec = (bn: BigNumber, decimals = 18): number => {
  return bn.dividedBy(new BigNumber(10).pow(decimals)).toNumber()
}

export const secDiffToTimeString = (sec: number): string =>{
  const day = Math.floor(sec / 86400);
  const dayString = day>0?`${day} Day${day>1?"s, ":", "}`:"";
  const hour = Math.floor((sec % 86400) / 3600);
  const hourString = hour>0?`${hour} Hour${hour>1?"s, ":", "}`:"";
  const min = Math.floor((sec % 3600) / 60);
  const minString = min>0?`${min} Minute${min>1?"s, and ":", and "}`:"";
  const secs = Math.floor(sec % 60);
  const secsString = `${secs} Second${secs>1?"s":""}`;
  return `${dayString}${hourString}${minString}${secsString}`;
};

export const isAddress = (address) => {
  return Web3.utils.isAddress(address) ? address : undefined;
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
  
      // Generate random number
      const j = Math.floor(Math.random() * (i + 1));
                  
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
  }
      
  return array;
}

export const clone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
}

export const getBchPrice = async() => {
  let price = null;

  try {
    await fetch("https://api2.benswap.cash/sbchPrice").then((response)=>{
      return response.ok ? response.json() : null;
    }).then((r) =>{
      if (r && r.isSuccess) {
          price = Number.isNaN(Number(r.price))?null:r.price;
      }
    });
  } catch (e) {
    console.error(e);
  }

  if (!price) {
    try {
      await fetch("https://api.benswap.cash/api/smartbch/price").then((response)=>{
        return response.ok ? response.text() : null;
      }).then((text) =>{
        price = Number.isNaN(Number(text))?null:text;
      });
    } catch (e) {
      console.error(e);
    }
  }

  return price;
}

export const arrayEquals = (a, b) => {
  return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
}

export const loadImage = (url) => {
  return new Promise(r => { const i = new Image(); i.onload = (() => r(i)); i.src = url; }) as any;
}
