import { ethers } from 'ethers'
import addresses from 'config/constants/contracts'

const chainId = process.env.REACT_APP_CHAIN_ID

export const isAddressMatch = (address1, address2) => {
  return address1 && address2 && address1.toLowerCase() === address2.toLowerCase();
};

export const getChecksumAddress = (value) => {
  try {
      return ethers.utils.getAddress(value.toLowerCase())
  } catch (err) {
      return false
  }
}

export const getCakeAddress = () => {
  return addresses.cake[chainId]
}
export const getEbenAddress = () => {
  return addresses.eben[chainId]
}
export const getMasterChefAddress = () => {
  return addresses.masterChef[chainId]
}
export const getMulticallAddress = () => {
  return addresses.mulltiCall[chainId]
}
export const getLotteryAddress = () => {
  return addresses.lottery[chainId]
}
export const getLotteryTicketAddress = () => {
  return addresses.lotteryNFT[chainId]
}
export const getTempleAddress = () => {
  return addresses.temple[chainId]
}
export const getLpFactoryAddress = () => {
  return addresses.lpFactory[chainId]
}
export const getWbchAddress = () => {
  return addresses.wbch[chainId]
}
export const getPresellerAddress = () => {
  return addresses.preseller[chainId]
}
export const getIboFactoryAddress = () => {
  return addresses.iboFactory[chainId]
}
export const getBenSwapLpValidatorAddress = () => {
  return addresses.benSwapLpValidator[chainId]
}
export const getBenLockFactoryAddress = () => {
  return addresses.benLockFactory[chainId]
}
export const getSep20FactoryAddress = () => {
  return addresses.sep20Factory[chainId]
}
export const getSep20TemplateBasicAddress = () => {
  return addresses.sep20TemplateBasic[chainId]
}
export const getSep20TemplateLiquidityGeneratorAddress = () => {
  return addresses.sep20TemplateLiquidityGenerator[chainId]
}
export const getSep20TemplateAnnuityAddress = () => {
  return addresses.sep20TemplateAnnuity[chainId]
}
export const getSep20TemplateMultiMintAddress = () => {
  return addresses.sep20TemplateMultiMint[chainId]
}
export const getSep20TemplateInscriptionsAddress = () => {
  return addresses.sep20TemplateInscriptions[chainId]
}
export const getNullAddress = () => {
  return "0x000000000000000000000000000000000000dEaD"
}
export const getGenesisAddress = () => {
  return "0x0000000000000000000000000000000000000000"
}
export const getRanchFactoryAddress = () => {
  return addresses.ranchFactory[chainId]
}
export const getIboV2FactoryAddress = () => {
  return addresses.iboV2Factory[chainId]
}
export const getIboV2SimpleSendingOfferingTokensConclusionStrategyAddress = () => {
  return addresses.iboV2SimpleSendingOfferingTokensConclusionStrategy[chainId]
}
export const getIboV2SimpleSendingRaisingTokensConclusionStrategyAddress = () => {
  return addresses.iboV2SimpleSendingRaisingTokensConclusionStrategy[chainId]
}
export const getIboV2TimeLockRaisingTokensConclusionStrategyAddress = () => {
  return addresses.iboV2TimeLockRaisingTokensConclusionStrategy[chainId]
}
export const getIboV2TimeLockOfferingTokensConclusionStrategyAddress = () => {
  return addresses.iboV2TimeLockOfferingTokensConclusionStrategy[chainId]
}
export const getIboV2AddRaisingTokensToLiquidityAndLockConclusionStrategyAddress = () => {
  return addresses.iboV2AddRaisingTokensToLiquidityAndLockConclusionStrategy[chainId]
}
export const getIboV2SendingOfferingTokensToRaisingTokenConclusionStrategyAddress = () => {
  return addresses.iboV2SendingOfferingTokensToRaisingTokenConclusionStrategy[chainId]
}
export const getCandyHouseAddress = () => {
  return addresses.candyHouse[chainId]
}
export const getEbenGovAddress = () => {
  return addresses.ebenGov[chainId]
}
export const getPokeBenAddress = () => {
  return addresses.pokeBen[chainId]
}
export const getPokeBenHuntV2Address = () => {
  return addresses.pokeBenHuntV2[chainId]
}
export const getPokeBenNftNameExtensionAddress = () => {
  return addresses.pokeBenNftNameExtension[chainId]
}
export const getAirdropperAddress = () => {
  return addresses.airdropper[chainId]
}
export const getPokeBenItemAddress = () => {
  return addresses.pokeBenItem[chainId]
}
export const getPokeBenAbilityExtAddress = () => {
  return addresses.pokeBenAbilityExt[chainId]
}
export const getPokeBenAbilityTrainingRoomAddress = () => {
  return addresses.pokeBenAbilityTrainingRoom[chainId]
}
export const getPokeBenEvoluterV1Address = () => {
  return addresses.pokeBenEvoluterV1[chainId]
}
export const getPokeBenTeamExtAddress = () => {
  return addresses.pokeBenTeamExt[chainId]
}
export const getPokeBenPvpExtAddress = () => {
  return addresses.pokeBenPvpExt[chainId]
}
export const getPokeBenShopAddress = () => {
  return addresses.pokeBenShop[chainId]
}
export const getPokeBenHeroAddress = () => {
  return addresses.pokeBenHero[chainId]
}
export const getPokeBenHeroPartAppraiserAddress = () => {
  return addresses.pokeBenHeroPartAppraiser[chainId]
}
export const getPokeBenHeroBeauticianAddress = () => {
  return addresses.pokeBenHeroBeautician[chainId]
}
export const getFarmVotingAddress = () => {
  return addresses.farmVoting[chainId]
}
export const getNFTEXAddress = () => {
  return addresses.NFTEX[chainId]
}
export const getNftFactorySingleAddress = () => {
  return addresses.nftFactorySingle[chainId]
}
export const getFogHeroAddress = () => {
  return addresses.fogHero[chainId]
}
export const getFogItemAddress = () => {
  return addresses.fogItem[chainId]
}
export const getTokenBurnerAddress = () => {
  return addresses.tokenBurner[chainId]
}
export const getPokeBenCrystalAddress = () => {
  return addresses.pokeBenCrystal[chainId]
}
export const getCharitiesAddress = () => {
  return addresses.charities[chainId]
}
export const getCemeteryControllerAddress = () => {
  return addresses.cemeteryController[chainId]
}
export const getCemeteryNftAddress = () => {
  return addresses.cemeteryNft[chainId]
}
