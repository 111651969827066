import React, { useEffect, Suspense, lazy, useState } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { ResetCSS } from '@pancakeswap-libs/uikit'
import { connWallt } from 'utils/wallet'
import BigNumber from 'bignumber.js'
import { useFetchPublicData } from 'state/hooks'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import PageLoader from './components/PageLoader'
import NftGlobalNotification from './views/Nft/components/NftGlobalNotification'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page'
const Started = lazy(() => import('./views/Started'))
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const Votes = lazy(() => import('./views/Votes'))
const Lottery = lazy(() => import('./views/Lottery'))
const CreateIBO = lazy(() => import('./views/IBO/Create'))
const ActiveIBOs = lazy(() => import('./views/IBO/Active'))
const MyIBOs = lazy(() => import('./views/IBO/My'))
const BenLock = lazy(() => import('./views/BenLock'))
const Sep20Factory = lazy(() => import('./views/Sep20Factory'))
const RanchExplorer = lazy(() => import('./views/Ranches/RanchExplorer'))
const CreateRanch = lazy(() => import('./views/Ranches/CreateRanch'))
const MyRanches = lazy(() => import('./views/Ranches/MyRanches'))
const FavoriteRanches = lazy(() => import('./views/Ranches/FavoriteRanches'))
const LpFinder = lazy(() => import('./views/LpFinder'))
const CreateIboV2 = lazy(() => import('./views/IBOv2/CreateIboV2'))
const MyIboV2s = lazy(() => import('./views/IBOv2/MyIbos'))
const IboV2Explorer = lazy(() => import('./views/IBOv2/IboExplorer'))
const FavoriteIboV2s = lazy(() => import('./views/IBOv2/FavoriteIbos'))
const SpotlightIboV2s = lazy(() => import('./views/IBOv2/SpotlightIbos'))
const CandyHouseExplorer = lazy(() => import('./views/CandyHouse/CandyHouseExplorer'))
const CharityExplorer = lazy(() => import('./views/Charities/CharityExplorer'))
const PokeBen = lazy(() => import('./views/PokeBen'))
// const Pools = lazy(() => import('./views/Pools'))
// const Ifos = lazy(() => import('./views/Ifos'))
const ComingSoon = lazy(() => import('./views/ComingSoon'))
const NotFound = lazy(() => import('./views/NotFound'))
const Airdrop = lazy(() => import('./views/Airdrop'))
const NftFactory = lazy(() => import('./views/NftFactory'))

const NftFeatured = lazy(() => import('./views/NftMarket/NftFeatured'))
const NftTokenDetail = lazy(() => import('./views/NftMarket/NftTokenDetail'))
const NftExplorer = lazy(() => import('./views/NftMarket/NftExplorer'))
const NftMyAuctions = lazy(() => import('./views/NftMarket/NftMyAuctions'))
const NftFavorites = lazy(() => import('./views/NftMarket/NftFavorites'))

const Cemetery = lazy(() => import('./views/Cemetery/Cemetery'))
const CremeteryNftDetail = lazy(() => import('./views/Cemetery/CremeteryNftDetail'))

const Bridge = lazy(() => import('./views/Bridge'))
const BlindBoxes = lazy(() => import('./views/BlindBoxes'))

// const Nft = lazy(() => import('./views/Nft'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  const { account, connect, status } = useWallet();
  useEffect(() => {
    if (!account && window.localStorage.getItem('accountStatus')) {
      connect('injected')
    }
  }, [account, connect])

  useFetchPublicData()

  // const [on, setOn] = useState(false);
  // if(!on && !window.localStorage.getItem('logoOut')){
  //   connWallt('Metamask',connect,status)
  //   setOn(true)
  // }
  // if(window.localStorage.getItem('logoOut')){
  //   setTimeout(()=>{
  //     window.localStorage.removeItem('logoOut')
  //   },2000)
  // }
  return (
    <Router>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <Suspense fallback={<PageLoader />}>
          <Switch>            
            <Route path="/Started" exact>
              <Started />
            </Route>
            <Route path="/" exact>
              <Home />
            </Route>
            
            <Route path="/nft/featured/:page">
              <NftFeatured />
            </Route>
            <Route path="/nft/featured">
              <NftFeatured />
            </Route>
            <Route path="/nft/favorites/:page">
              <NftFavorites />
            </Route>
            <Route path="/nft/favorites">
              <NftFavorites />
            </Route>
            <Route path="/nft/token/:address/:id">
              <NftTokenDetail />
            </Route>
            <Route path="/nft/collection/:address/:tab/:page">
              <NftExplorer />
            </Route>
            <Route path="/nft/collection/:address/:tab">
              <NftExplorer />
            </Route>
            <Route path="/nft/collection/:address">
              <NftExplorer />
            </Route>
            <Route path="/nft/collection">
              <NftExplorer />
            </Route>
            <Route path="/nft/myorders/:tab">
              <NftMyAuctions />
            </Route>
            <Route path="/nft/myorders">
              <NftMyAuctions />
            </Route>
          
            <Route path="/pokeben/:tab/:arg1/:arg2/:arg3">
              <PokeBen />
            </Route>
            <Route path="/pokeben/:tab/:arg1/:arg2">
              <PokeBen />
            </Route>
            <Route path="/pokeben/:tab/:arg1">
              <PokeBen />
            </Route>
            <Route path="/pokeben/:tab">
              <PokeBen />
            </Route>
            <Route path="/pokeben">
              <PokeBen />
            </Route>

            <Route path="/votes/:tab">
              <Votes />
            </Route>
            <Route path="/votes">
              <Votes />
            </Route>
            
            <Route path="/nftfactory/:tab">
              <NftFactory />
            </Route>
            <Route path="/nftfactory">
              <NftFactory />
            </Route>
            
            <Route path="/lottery/:tab">
              <Lottery />
            </Route>
            <Route path="/lottery">
              <Lottery />
            </Route>

            <Route path="/farms">
              <Farms />
            </Route>
            <Route path="/pools">
              <Farms tokenMode />
            </Route>
            <Route path="/benlock">
              <BenLock />
            </Route>
            <Route path="/ranches">
              <RanchExplorer />
            </Route>
            <Route path="/ranch/create">
              <CreateRanch />
            </Route>
            <Route path="/ranch/my">
              <MyRanches />
            </Route>
            <Route path="/ranch/favorites">
              <FavoriteRanches />
            </Route>
            <Route path="/ibo/create">
              <CreateIBO />
            </Route>
            <Route path="/ibo/active">
              <ActiveIBOs />
            </Route>
            <Route path="/ibo/my">
              <MyIBOs />
            </Route>
            <Route path="/sep20">
              <Sep20Factory />
            </Route>
            <Route path="/lpfinder">
              <LpFinder />
            </Route>
            <Route path="/airdrop">
              <Airdrop />
            </Route>
            <Route path="/bridge">
              <Bridge />
            </Route>
            <Route path="/ibov2/create">
              <CreateIboV2 />
            </Route>
            <Route path="/ibov2/my">
              <MyIboV2s />
            </Route>
            <Route path="/ibov2/explorer">
              <IboV2Explorer />
            </Route>
            <Route path="/ibov2/favorites">
              <FavoriteIboV2s />
            </Route>
            <Route path="/ibov2/spotlight">
              <SpotlightIboV2s />
            </Route>
            <Route path="/candyhouse/explorer">
              <CandyHouseExplorer />
            </Route>
            <Route path="/charities/explorer">
              <CharityExplorer />
            </Route>
            <Route path="/BlindBoxes">
              <BlindBoxes />
            </Route>

            <Route path="/cemetery/:tab/:page">
              <Cemetery />
            </Route>
            <Route path="/cemetery/:tab">
              <Cemetery />
            </Route>
            <Route path="/cemetery">
              <Cemetery />
            </Route>
            <Route path="/cremetery/detail/:id">
              <CremeteryNftDetail />
            </Route>

            {/* <Route path="/ifo"> */}
            {/*  <Ifos /> */}
            {/* </Route> */}
            {/* Redirect */}
            {/* <Route path="/staking"> */}
            {/*  <Redirect to="/pools" /> */}
            {/* </Route> */}
            {/* <Route path="/syrup"> */}
            {/*  <Redirect to="/pools" /> */}
            {/* </Route> */}
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Menu>
      <NftGlobalNotification />
    </Router>
  )
}

export default React.memo(App)
